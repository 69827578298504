<template>
    <Wrapper>
        <Step :passed="step > 1" :active="step === 1">
            <span>1</span>
        </Step>
        <Step :passed="step > 2" :active="step === 2">
            <span>2</span>
        </Step>
        <Step :passed="step > 3" :active="step === 3">
            <span>3</span>
        </Step>
        <Step :passed="step > 4" :active="step === 4">
            <span>4</span>
        </Step>
    </Wrapper>
</template>

<script>
import styled from 'vue-styled-components'
import themes from '@/assets/themes'

const Wrapper = styled.div`
    display: flex;
`

const stepItemProps = {
  active: {
    type: Boolean,
    default: false
  },
  passed: {
    type: Boolean,
    default: false
  }
}

const Step = styled('div', stepItemProps)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-bottom: 1rem;

    %::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    &::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid ${stepItemProps => stepItemProps.passed ? themes.primaryColor : '#ccc'};
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    & span {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${stepItemProps => stepItemProps.active ? '50px' : '40px'};
        height: ${stepItemProps => stepItemProps.active ? '50px' : '40px'};
        margin-top: ${stepItemProps => stepItemProps.active ? '-5px' : '0px'};
        font-weight: ${stepItemProps => stepItemProps.active ? 'bold' : 'normal'};
        border-radius: 50%;
        background: #FFF;
        border: 2px solid ${themes.primaryColor};
        background-color: ${stepItemProps => stepItemProps.passed || stepItemProps.active ? themes.primaryColor : '#fff'};
        color: ${stepItemProps => stepItemProps.passed || stepItemProps.active ? '#fff' : themes.textColor};
        margin-bottom: 6px;
    }

    &:first-child::before {
        content: none;
    }
    &:last-child::after {
        content: none;
    }
`

export default {
  components: {
    Wrapper,
    Step
  },
  props: {
    step: {
      type: Number,
      required: true
    },
  },
}
</script>

