
export default {
  router: {
    profile: 'profile',
    chooser: 'activationchooser',
    isakey: 'isakeyactivation',
    login: 'login',
    deactivationIsaKey: 'isakeydeactivation',
    deactivationSmartcardItsme: 'smartcardItsmeDeactivation'
  }
}
