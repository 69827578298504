import Vue from 'vue'
import Router from 'vue-router'
import UserProfile from '@/views/UserProfile.vue'
import ActivationChooser from '@/views/ActivationChooser.vue'
import IsaKeyActivation from '@/views/IsaKeyActivation.vue'
import IsaKeyDeactivation from "@/views/IsaKeyDeactivation";
import SmartcardItsmeDeactivation from "@/views/SmartcardItsmeDeactivation"
import LoginScreen from '@/views/LoginScreen.vue'
import Const from './services/Constants'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/' + Const.router.profile,
      name: Const.router.profile,
      component: UserProfile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/' + Const.router.chooser,
      name: Const.router.chooser,
      component: ActivationChooser,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/' + Const.router.isakey,
      name: Const.router.isakey,
      component: IsaKeyActivation,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/' + Const.router.login,
      name: Const.router.login,
      component: LoginScreen,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/',
      redirect: { name: Const.router.login }
    },
    {
      path: '/' + Const.router.deactivationIsaKey,
      name: Const.router.deactivationIsaKey,
      component: IsaKeyDeactivation,
      props: (route) => ({
        ...route.params
      }),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/' + Const.router.deactivationSmartcardItsme,
      name: Const.router.deactivationSmartcardItsme,
      component: SmartcardItsmeDeactivation,
      props: (route) => ({
        ...route.params
      }),
      meta: {
        requiresAuth: true
      }
    }
  ]
})
