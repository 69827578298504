import oidc from 'oidc-client'
import axiosApi from './AxiosService'

const uiUrl = process.env.VUE_APP_UI_URL
const domain = process.env.VUE_APP_AM_URL

/* For local dev, allow to set header instead of IG. */
let setHeader = process.env.VUE_APP_ADD_USER_HEADER
setHeader = setHeader === 'true' // This is on a separate line on purpose to avoid issues with the run.sh script that replaces env variables.

/* For local dev, allow to bypass authentication. */
let bypassAm = process.env.VUE_APP_BYPASS_AM
bypassAm = bypassAm === 'true' // This is on a separate line on purpose.
const bypassAmUserId = process.env.VUE_APP_BYPASS_AM_USERID
const bypassAmRole = process.env.VUE_APP_BYPASS_AM_ROLE

const userManager = new oidc.UserManager({
  userStore: new oidc.WebStorageStateStore({ store: window.sessionStorage }),
  authority: domain,
  client_id: 'umanuiClient',
  redirect_uri: uiUrl + '/callback.html',
  post_logout_redirect_uri: uiUrl,
  response_type: 'code',
  scope: 'openid uman',
  filterProtocolClaims: true,
  prompt: 'login'
})

// For dev
oidc.Log.logger = console
oidc.Log.level = oidc.Log.INFO

export default class SecurityService {
  /**
   * Signs in the user.
   */
  signIn (signArgs) {
    if (bypassAm) {
      window.sessionStorage.setItem('dummyAuthenticated', true)
      window.sessionStorage.setItem('dummyUserId', bypassAmUserId)
      window.sessionStorage.setItem('dummyUserRole', bypassAmRole)
      window.location.reload()
      return
    }

    userManager
    .signinRedirect(signArgs)
    .catch(function (err) {
      console.log(err)
    })
  }

  /**
   * Signs out the user. The optional postLogoutUrl parameter is used to override the default return url after logout.
   */
  signOut (postLogoutUrl) {
    if (bypassAm) {
      window.sessionStorage.setItem('dummyAuthenticated', false)
      window.sessionStorage.setItem('dummyUserId', null)
      window.sessionStorage.setItem('dummyUserRole', null)
      window.location.href = postLogoutUrl ?? process.env.VUE_APP_UI_URL
    }

    let params = postLogoutUrl ? { post_logout_redirect_uri: postLogoutUrl } : {}
    userManager.signoutRedirect(params).then(() => {
      axiosApi.defaults.headers.common['Authorization'] = '' // eslint-disable-line
    }).catch(function (err) {
      console.log(err)
    })
  }

  /**
   * Returns the user id.
   */
  getUserId () {
    return new Promise((resolve, reject) => {
      if (bypassAm) {
        return resolve(window.sessionStorage.getItem('dummyUserId'))
      }

      userManager.getUser().then(function (user) {
        if (user == null) {
          return resolve(null)
        } else {
          return resolve(user.profile.sub)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  }

  /**
   * Returns true if the user is authenticated. Also set the appropriate headers.
   */
  isAuthenticated () {
    return new Promise((resolve, reject) => {
      if (bypassAm) {
        const auth = window.sessionStorage.getItem('dummyAuthenticated') === 'true'

        if (auth) {
          axiosApi.defaults.headers.common['Intelligate-User-Name'] = window.sessionStorage.getItem('dummyUserId')
          axiosApi.defaults.headers.common['Intelligate-User-Role'] = window.sessionStorage.getItem('dummyUserRole')
        } else {
          axiosApi.defaults.headers.common['Intelligate-User-Name'] = null
          axiosApi.defaults.headers.common['Intelligate-User-Role'] = null
        }

        return resolve(auth)
      }

      userManager.getUser().then(function (user) {
        if (user == null) {
          return resolve(false)
        } else {
          console.log('set access token', user.access_token)
          axiosApi.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}` // eslint-disable-line

          /* For local dev. */
          if (setHeader) {
            console.log('Add user header')
            axiosApi.defaults.headers.common['Intelligate-User-Name'] = user.profile.sub
            axiosApi.defaults.headers.common['Intelligate-User-Role'] = bypassAmRole
          }
          return resolve(true)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  }
}
