<template>
  <b-button
    style="float: left;"
    @click="$emit('click')"
  >
    <span v-if="playFlag" >
      <span style="float: left;">&nbsp;</span>
      <span class="link-like">{{ $t('common.playAnimation') }}</span>
      <span>&nbsp;</span>
      <img :src="cameraPlay" alt="camera_Play" />
    </span>
    <span v-else >
      <span style="float: left;">&nbsp;</span>
      <span class="link-like">{{ $t('common.stopAnimation') }}</span>
      <span>&nbsp;</span>
      <img :src="cameraStop" alt="camera_Stop" />
    </span>
  </b-button>
</template>


<style scoped>
.link-like {
  float: left;
  cursor: pointer;
  color: #007093;
  text-decoration-line: underline;
}
</style>


<script>
export default {
  data() {
    return {
      play: true,
      cameraPlay: require(`../assets/images/cameraPlay.png`),
      cameraStop: require(`../assets/images/cameraStop.png`)
    }
  },
  methods: {
    togglePlayFlag() {
      this.play = !this.play;
    }
  },
  computed: {
    playFlag() {
      return this.play;
    }
  }
}

</script>
