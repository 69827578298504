<template>
  <div class="container container-fluid">
    <div class="row justify-content-center">
      <div class="col title" :style="{ color: primaryColor }">
        {{ $t('chooser.title') }}
      </div>
    </div>
    <div class="row justify-content-center no-gutters">
      <StyledMeanContainer
        class="col d-flex"
        v-for="meanToActivate in means"
        v-bind:key="meanToActivate"
        v-on:click="selectMean(meanToActivate)"
      >
        <img class="meanImage" :src='"@/assets/images/" + meanToActivate + ".svg"'>
      </StyledMeanContainer>
    </div>
    <div class="row justify-content-center">
      <StyledButton
        class="col buttonCancel"
        v-on:click="goToProfile()">
        {{ $t('isakey.cancel') }}
      </StyledButton>
    </div>
  </div>
</template>

<style scoped>
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }

  .meanImage {
    padding: 15%;
    width: 100%;
  }

  .buttonCancel {
    max-width: 200px;
    margin: 20px;
  }
</style>

<script>
import styled from 'vue-styled-components'
import themes from '@/assets/themes'
import StyledButton from '@/components/styled/StyledButton'
import Const from '@/services/Constants'

const StyledMeanContainer = styled.div`
  flex: 1 1 auto !important;
  min-width: 100px !important;
  max-width: 150px !important;
  margin: 10px;
  border: solid 1px ${themes.primaryColor};
  border-radius: 6px;
  box-shadow: 2px 2px 2px gray;
  cursor: pointer;
  background-color: white;

   &:hover {
    background-color: ${themes.primaryColorDark};
  }
`

export default {
  components: {
    StyledButton,
    StyledMeanContainer
  },
  data () {
    return {
      means: null,
      primaryColor: themes.primaryColor
    }
  },
  created () {
    this.means = ['smartcard', 'isakey', 'itsme']
  },
  methods: {
    selectMean (mean) {
      if (mean === 'smartcard') {
        window.open('https://www.isabel.eu/start', '_blank')
      } else if (mean === 'isakey') {
        this.$router.push('isakeyactivation')
      } else if (mean === 'itsme') {
        window.location.replace(`${process.env.VUE_APP_AM_HOST}/ui/login?service=itsmeTree&goto=${encodeURIComponent(process.env.VUE_APP_UI_URL)}&realm=/isabel&locale=en&ForceAuth=true`)
      }
    },
    goToProfile () {
      this.$router.push(Const.router.profile)
    }
  }
}
</script>
