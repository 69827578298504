import { render, staticRenderFns } from "./ModalWindow.vue?vue&type=template&id=a8c9f2da&scoped=true"
import script from "./ModalWindow.vue?vue&type=script&lang=js"
export * from "./ModalWindow.vue?vue&type=script&lang=js"
import style0 from "./ModalWindow.vue?vue&type=style&index=0&id=a8c9f2da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8c9f2da",
  null
  
)

export default component.exports