import styled from 'vue-styled-components'
import themes from '@/assets/themes'

export const StyledH1 = styled.h1`
  color: ${themes.primaryColor};
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`

export const StyledH3 = styled.h3`
  color: ${themes.primaryColor};
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
`

export default StyledH1
