import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import UserManager from './services/SecurityService'
import Const from './services/Constants'
import 'bootstrap/dist/css/bootstrap-grid.css'

Vue.config.productionTip = false

const userManager = new UserManager()

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    userManager.isAuthenticated().then((result) => {
      if (result) {
        const afterLogin = localStorage.getItem('afterLogin')
        if (afterLogin) {
          localStorage.removeItem('afterLogin')
          router.push({ name: afterLogin})
        } else {
          next()
        }
      } else {
        window.localStorage.setItem('afterLogin', to.name)
        router.push({ name: Const.router.login, query: to.query })
      }
    })
  } else {
    next()
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
