<template>
  <StyledArticle id="app">
    <LanguageChooser />
    <header>
      <HeaderLogo />
      <UserSettings v-if="authenticated"/>
    </header>
    <main>
      <ShowAnimation
          ref="showAnimationRef"
          v-if="animationFile"
          :animation-filename=$t(animationFile)
      />
      <MainCard>
        <AnimationButton
            ref="animationBtnRef"
            v-if="animationFile"
            @click="toggleAnimation"
        />

        <HelpLink
          @stepChanged="doStepChangedActions"
          v-if="this.helpLink"
          :help-link="this.helpLink"
        />
        <LabelError
          @errorPath="setErrorPath"
          v-if="errorPath"
          :error-path="errorPath"
        />
        <div class="content">
          <router-view @errorPath="setErrorPath" @stepChanged="doStepChangedActions" />
        </div>
      </MainCard>
    </main>
    <footer>
      <div class="footer-left">&copy; {{ $t('common.footer.copyright', {year: copyrightYear}) }} | <a href="https://www.isabelgroup.eu/" target="_blank">{{ $t('common.footer.isabelGroup') }}</a> | <a href="https://www.isabel.eu/disclaimer" target="_blank">{{ $t('common.footer.privacyPolicy') }}</a> | <a href="https://www.isabel.eu/disclaimer" target="_blank">{{ $t('common.footer.dataProtection') }}</a></div>
      <div class="footer-right"><i class="material-icons">lock</i> {{ $t('common.footer.securedBy') }}</div>
    </footer>
  </StyledArticle>
</template>

<style>
  html {
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    background: rgba(196, 196, 196, 0.5);
  }

  header, footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem 6%;
  }

  footer {
    font-size: 12px;
  }

  .footer-right {
    font-weight: bold;
  }

  .head-right {
    display: flex;
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    padding: 3rem 5%;
  }

  .error-label + .content {
    padding: 0 5% 3rem;
  }

  a {
    text-decoration-line: underline;
    cursor: pointer;
    color: #007093;
  }

</style>

<style scoped>
  i {
    vertical-align: middle;
    font-size: 20px !important;
    padding-bottom: 6px;
  }
</style>

<script>
import UserManager from './services/SecurityService'

import LanguageChooser from '@/components/LanguageChooser.vue'
import UserSettings from '@/components/UserSettings.vue'
import LabelError from '@/components/LabelError.vue'
import HeaderLogo from '@/components/HeaderLogo.vue'
import HelpLink from '@/components/HelpLink.vue'

import styled from 'vue-styled-components'
import themes from '@/assets/themes'
import { Card } from '@/components/styled/Card'
import ShowAnimation from "@/components/ShowAnimation.vue";
import AnimationButton from "@/components/AnimationButton.vue";

const StyledArticle = styled.article`
  color: ${themes.textColor};
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  max-width: 1300px;
  min-width: 80vw;
  margin: auto;
`

const MainCard = styled(Card)`
  width: 65%;
  max-width: 800px;
`

export default {
  name: 'app',
  components: {
    AnimationButton,
    ShowAnimation,
    MainCard,
    StyledArticle,
    LanguageChooser,
    UserSettings,
    LabelError,
    HeaderLogo,
    HelpLink
  },
  watch: {
    '$route': 'resetData'
  },
  data () {
    return {
      userManager: new UserManager(),
      authenticated: false,
      errorPath: null,
      copyrightYear: '2021',
      helpLink: null,
      animationFile: null
    }
  },
  created () {
    const currentYear = new Date().getFullYear().toString()
    if (currentYear !== this.copyrightYear) {
      this.copyrightYear = this.copyrightYear + '-' + currentYear
    }
    this.userManager.isAuthenticated().then((result) => {
      this.authenticated = result
    })
  },
  methods: {
    login () {
      this.userManager.signIn({ ui_locales: document.documentElement.lang })
    },
    setErrorPath (path) {
      this.errorPath = path
    },
    doStepChangedActions (step) {
      this.helpLink = `isakey.helpUrl${step}`
      this.animationFile = ( step < 1 || step > 4) ? null :`isakey.animationFileForStep${step}`
    },
    toggleAnimation() {
      this.$refs.showAnimationRef.toggleVisibility()
      this.$refs.animationBtnRef.togglePlayFlag()
    },
    resetData() {
      this.helpLink = null
      this.errorPath = null
      this.animationFile = null
    }
  }
}
</script>
