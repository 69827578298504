import styled from 'vue-styled-components'
import themes from '@/assets/themes'

const props = {
  filled: {
    type: Boolean,
    default: false
  }
}

const StyledButton = styled('button', props)`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 4px;
  padding: 1rem 3rem;
  text-transform: uppercase;
  text-decoration: none;

  background-color: ${props => props.filled ? themes.primaryColor : '#fff'};
  color: ${props => props.filled ? '#fff' : themes.primaryColor};
  border-color: ${themes.primaryColor};
  border: 1px solid ${themes.primaryColor};
  box-shadow: 0px 2px 8px rgba(56, 60, 74, 0.15);
`

export default StyledButton
