<template>
  <div class="container">
    <div class="card">
      <StyledH1>{{ $t('profile.profileCard.title') }}</StyledH1>
      <label>{{ $t('profile.profileCard.isabelUserId') }}
          <input v-if="profile.username" type="text" readonly :value="profile.username">
          <input v-else-if="profile.isabelUserId" type="text" readonly :value="profile.isabelUserId">
        <StyledInput v-else type="text" readonly :value="$t('profile.profileCard.noUsername')" />
      </label>
      <label>{{ $t('profile.profileCard.fullName') }}
        <input type="text" readonly :value="profile.fullName">
      </label>
      <label>{{ $t('profile.profileCard.companyName') }}
        <input type="text" readonly :value="profile.customerName">
      </label>
    </div>
    <div class = "card">
      <StyledH1>{{ $t('profile.tokenCard.title') }}</StyledH1>
      <div v-if="tokenList" class="token-list">
        <div
          v-for="token in tokenList"
          v-bind:key="`${token.type}${token.index}`"
          v-on:click="switchTokenDetails(token)"
          v-bind:class="{ 'token-card-selected': token.displayDetails }"
          class="token-card">
          <div
            class="token-img"
            :style="{
              backgroundImage: `url(${require('@/assets/images/' +  token.img)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '30px',
              backgroundPosition: '10px 3px' }"
            >
          </div>
        </div>
      </div>
      <TokenDetails
          v-if="tokenDetails"
          :token="tokenDetails"
          :token-list="tokenList"
          :profile="profile"
      />
      <div
        class="add-token"
        v-if="profile && profile.userRoles && (profile.userRoles.includes('ROLE_USER') || profile.userRoles.includes('ROLE_ACTIVATOR'))"
        v-on:click="addToken()">
        <i class="material-icons">add_circle_outline</i>{{ $t('profile.tokenCard.addToken')}}
      </div>
    </div>
  </div>
</template>

<style scoped>
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  label {
    padding: 0.25em;
  }

  input {
    outline: none;
    border: none;
    font-weight: bold;
  }

  .token-list {
    overflow: auto;
  }

  .token-card {
    margin: 5px;
    float: left;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .token-card:hover {
    background-color:rgb(255, 240, 246);
  }

  .token-card-selected {
    background-color: rgb(247, 220, 231);
  }

  .token-img {
    float: left;
    width: 50px;
    height: 100%;
  }

  .add-token {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration-line: underline;
    opacity: 0.87;
    margin-top: 30px;
    cursor: pointer;
  }

  i {
    vertical-align: middle;
    font-size: small !important;
  }

  .authentication-icon {
    float: right;
  }

  .signature-icon {
    float: right;
  }

  button {
    width: 80%;
  }
</style>

<script>
import axiosApi from '@/services/AxiosService'
import UserManager from '@/services/SecurityService'
import TokenService from '@/services/TokenService'
import Const from '@/services/Constants'

import StyledH1 from '@/components/styled/StyledTitle'
import TokenDetails from '@/components/TokenDetails.vue'

import styled from 'vue-styled-components'
import themes from '@/assets/themes'

const StyledInput = styled.input`
  color: ${themes.primaryColor};
`

export default {
  components: {
    StyledH1,
    TokenDetails,
    StyledInput
  },
  data () {
    return {
      userManager: new UserManager(),
      tokenService: new TokenService(),
      isabelUserId: null,
      profile: {},
      tokenList: null,
      addTokenErrorMessage: null,
      tokenDetails: null
    }
  },
  created () {
    this.userManager.getUserId().then((userId) => {
      this.isabelUserId = userId
      this.getProfile()
      this.getTokens()
    })
  },
  methods: {
    getProfile () {
      axiosApi
        .get(`/users/${this.isabelUserId}`)
        .then((data) => {
          this.$emit('errorPath', null)
          this.profile = data.data
        })
        .catch(() => {
          this.$emit('errorPath', 'profile.errors.getProfile')
        })
    },
    getTokens () {
      axiosApi
        .get(`/users/${this.isabelUserId}/tokens`)
        .then((data) => {
          this.$emit('errorPath', null)
          this.tokenList = data.data
            .sort(this.tokenService.getSortFunction())
            .map((item, index) => {
            /* Add custom attributes for display. */
              item.index = index
              item.displayDetails = false
              this.tokenService.fillAttributes(item)
              return item
            })
        })
        .catch(() => {
          this.$emit('errorPath', 'profile.errors.getTokens')
        })
    },
    switchTokenDetails (token) {
      if (!token.displayDetails) {
        this.tokenList.map((t) => { t.displayDetails = false })
        token.displayDetails = true
        this.tokenDetails = token
      } else {
        token.displayDetails = false
        this.tokenDetails = null
      }
    },
    addToken () {
      this.$router.push(Const.router.chooser)
    }
  }
}
</script>
