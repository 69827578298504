<template>
  <div>
    {{ $t('login.loginMessage1') }} <a v-on:click="login">{{ $t('login.loginMessage2') }}</a>
  </div>
</template>

<style scoped>
  a {
    cursor: pointer;
    text-decoration-line: underline;
  }
</style>

<script>
import UserManager from '@/services/SecurityService'
import Const from '@/services/Constants'

const acrMapping = {
  isabelsmartcard: 'urn:isabelgroup:acr:isabelsmartcard',
  eid: 'urn:isabelgroup:acr:beid',
  isakey: 'urn:isabelgroup:acr:isakey',
  activationcode: 'urn:isabelgroup:acr:activationcode'
}

export default {
  data () {
    return {
      userManager: new UserManager(),
      authenticated: false
    }
  },
  created () {
    this.userManager.isAuthenticated().then((result) => {
      this.authenticated = result

      if (this.$router.currentRoute.query.ui_locales) {
        this.setLanguage(this.$router.currentRoute.query.ui_locales.toLowerCase())
      }

      if (this.authenticated) {
        const afterLogin = localStorage.getItem('afterLogin')
        if (afterLogin) {
          localStorage.removeItem('afterLogin')
          this.$router.push({ name: afterLogin})
        } else {
          this.$router.push({ name: Const.router.profile })
        }
      } else if(this.$router.currentRoute.query.with) {
        this.userManager.signIn({ ui_locales: document.documentElement.lang, acr_values: acrMapping[this.$router.currentRoute.query.with.toLowerCase()]})
      }

    })
  },
  methods: {
    login () {
      this.userManager.signIn({ ui_locales: document.documentElement.lang })
    },
    setLanguage (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('locale', lang)
      document.title = this.$t('common.title')
      document.documentElement.lang = this.$i18n.locale
    }
  }
}
</script>
