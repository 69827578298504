<template>
  <div>
    <span style="float: right;">
      <a :href="$t(helpLink)" target="_blank" >{{ $t('common.needHelp') }}</a>
      &nbsp;
    </span>
    <br/>
  </div>
</template>

<script>

export default {
  props: {
    helpLink: {
      type: String,
      required: true
    }
  },
}

</script>
