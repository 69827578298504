<template>
  <div>
    <StyledH3>{{ $t('profile.tokenCard.tokenDetails') }}</StyledH3>
    <table>
      <tr v-if="tokenService.isSmartcard(token.type) && token.pkiUserId">
        <td>{{ $t('profile.tokenCard.smartcard.pkiUserId') }}:</td>
        <td>{{ token.pkiUserId }}</td>
      </tr>
      <tr v-if="tokenService.isSmartcard(token.type) && token.cardNumber">
        <td>{{ $t('profile.tokenCard.smartcard.number') }}:</td>
        <td>{{ token.cardNumber }}</td>
      </tr>
      <tr v-if="tokenService.isIsaKey(token.type) && token.serialNumber">
        <td>{{ $t('profile.tokenCard.isaKey.serial') }}:</td>
        <td>{{ token.serialNumber }}</td>
      </tr>
      <tr v-if="tokenService.isIsaKey(token.type) && token.license">
        <td>{{ $t('profile.tokenCard.isaKey.license') }}:</td>
        <td>{{ token.license }}</td>
      </tr>
      <tr v-if="tokenService.isIsaKey(token.type) && token.activation > 0">
        <td>{{ $t('profile.tokenCard.isaKey.instance') }}:</td>
        <td>{{ token.activation }}</td>
      </tr>
      <tr v-if="tokenService.isItsme(token.type) && token.itsmePhone">
        <td>{{ $t('profile.tokenCard.itsme.phone') }}:</td>
        <td>{{ token.itsmePhone }}</td>
      </tr>
    </table>
    <div
        class="delete-token"
        v-if="profile && profile.userRoles && profile.userRoles.includes('ROLE_USER') && primaryTokenAmount > 1"
        v-on:click="deleteToken(token)">
      <i class="material-icons">remove_circle_outline</i>{{ $t('profile.tokenCard.deleteToken')}}
    </div>
    <div
        class="prevent-delete-token"
        :title="$t('profile.tokenCard.prevent')"
        v-if="primaryTokenAmount <= 1 && !tokenService.isItsme(token.type)">
      <i class="material-icons">remove_circle_outline</i>{{ $t('profile.tokenCard.deleteToken')}}
    </div>
    <div
        class="allow-delete-itsme-one-primary"
        v-if="primaryTokenAmount <= 1 && tokenService.isItsme(token.type)"
        v-on:click="deleteToken(token)">
      <i class="material-icons">remove_circle_outline</i>{{ $t('profile.tokenCard.deleteToken')}}
    </div>
  </div>
</template>

<style scoped>
  label {
    padding: 0.25em;
  }

  input {
    outline: none;
    border: none;
    font-weight: bold;
  }

  .delete-token {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration-line: underline;
    opacity: 0.87;
    margin-top: 30px;
    cursor: pointer;
  }

  .prevent-delete-token {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration-line: underline;
    opacity: 0.87;
    margin-top: 30px;
    cursor: pointer;
    color: grey;
  }

  .allow-delete-itsme-one-primary {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration-line: underline;
    opacity: 0.87;
    margin-top: 30px;
    cursor: pointer;
  }

  i {
    vertical-align: middle;
    font-size: small !important;
  }

  button {
    width: 80%;
  }
</style>

<script>
import TokenService from '@/services/TokenService'
import Const from '@/services/Constants'
import { StyledH3 } from '@/components/styled/StyledTitle'

export default {
  components: {
    StyledH3
  },
  props: {
    token: {
      type: Object,
      required: true
    },
    tokenList: {
      type: Array,
      required: true
    },
    profile: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tokenService: new TokenService(),
      primaryTokenAmount: 0
    }
  },
  created() {
    this.getPrimaryTokenAmount()
  },
  methods: {
    getPrimaryTokenAmount() {
      for (let i = 0; i < this.tokenList.length; i++) {
        var type = this.tokenList[i].type
        if (this.tokenService.isIsaKey(type) || this.tokenService.isSmartcard(type)) {
          this.primaryTokenAmount++
        }
      }
    },
    deleteToken(token) {
      switch (token.type) {
        case 'ISAKEY':
          this.$router.push({
            name: Const.router.deactivationIsaKey,
            params: {
              token: token
            }
          })
          break;
        default:
          this.$router.push({
            name: Const.router.deactivationSmartcardItsme,
            params: {
              token: token
            }
          })
      }
    }
  }
}
</script>
