<template>
  <Transition name="modal-fade">

    <div class="modal-wrapper">
      <div class="modal-content">

        <div class="modal-title">{{ titleText }}</div>

        <div class="modal-body">
          <div class="modal-body-line">{{ bodyText }}</div>
          <div class="modal-body-line" v-if="bodyText2">{{ bodyText2 }}</div>
        </div>

        <div class="modal-footer">
          <StyledButton
            v-on:click="cancel"
            style="height: 30px; padding: 0px;">
            {{ cancelText }}
          </StyledButton>

          <StyledButton
            :filled=true
            v-on:click="confirm"
            v-on:keyup.enter="confirm"
            ref="nextButton"
            style="height: 30px; padding: 0px;">
            {{ confirmText }}
          </StyledButton>
        </div>
      </div>
    </div>

  </Transition>
</template>

<style scoped>
.modal-fade-enter-from,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: 0.25s ease all;
}

.modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: grid;
    place-items: center;
}

.modal-content {
    background: rgba(255, 255, 255, 1);
    width: 50%;
    max-width: 600px;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(56,60,74,0.15);
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.modal-title {
    padding: 1rem 5%;
    font-size: 24px;
}

.modal-body {
    padding: 0.5rem 5%;
}

.modal-body-line {
    padding-bottom: 10px;
}

.modal-footer {
    display: flex;
    margin-top: 1rem;
    padding: 0px 20px;
}

.modal-footer button {
    flex: 1;
}

.modal-footer button:nth-of-type(2) {
    margin-left: 0.5rem;
}
</style>

<script>
import StyledButton from '@/components/styled/StyledButton'

export default {
  components: {
    StyledButton
  },
  props: {
    titleText: null,
    bodyText: null,
    bodyText2: null,
    cancelText: null,
    confirmText: null
  },
  methods: {
    getBody() {
      if (this.bodyText2) {
        return this.bodyText + '<br />' + this.bodyText2
      }
      return this.bodyText
    },
    confirm() {
      this.$emit('confirm', null)
    },
    cancel () {
      this.$emit('cancel', null)
    }
  }
}
</script>