
const TYPE_SMARTCARD = 'ISABELSMARTCARD'
const TYPE_ITSME = 'ITSME'
const TYPE_ISAKEY = 'ISAKEY'

export default class TokenService {
  isSmartcard (type) {
    return type === TYPE_SMARTCARD
  }

  isItsme (type) {
    return type === TYPE_ITSME
  }

  isIsaKey (type) {
    return type === TYPE_ISAKEY
  }

  getImage (type) {
    if (type === TYPE_SMARTCARD) {
      return 'isabelsmartcard.png'
    }

    if (type === TYPE_ITSME) {
      return 'itsme.png'
    }

    if (type === TYPE_ISAKEY) {
      return 'onespandigipass.png'
    }

    return 'notfound.png'
  }

  fillAttributes (attributes) {
    attributes.img = this.getImage(attributes.type)
  }

  getSortFunction () {
    /* Compares a and b. Returns -1 if a < b, 0 if a = b, 1 if a > b. */
    return function (a, b) {
      if (a.type === TYPE_SMARTCARD && b.type === TYPE_SMARTCARD) {
        return a.cardNumber?.localeCompare(b.cardNumber)
      } else if (a.type === TYPE_SMARTCARD) {
        return -1
      } else if (b.type === TYPE_SMARTCARD) {
        return 1
      }

      if (a.type === TYPE_ISAKEY && b.type === TYPE_ISAKEY) {
        if (a.license !== b.license) {
          return a.license?.localeCompare(b.license)
        } else {
          return a.activation < b.activation ? -1 : 1
        }
      } else if (a.type === TYPE_ISAKEY) {
        return -1
      } else if (b.type === TYPE_ISAKEY) {
        return 1
      }

      if (a.type === TYPE_ITSME && b.type === TYPE_ITSME) {
        return 0
      } else if (a.type === TYPE_ITSME) {
        return -1
      } else if (b.type === TYPE_ITSME) {
        return 1
      }

      return a.type.localeCompare(b.type)
    }
  }
}
