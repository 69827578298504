<template>
  <div>
    <img
        :src='imgPath' alt="animation" class="center"
        v-show = 'visibility'
    />
  </div>
</template>

<style scoped>

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

</style>


<script>
export default {
  props: {
    animationFilename: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visibility: false
    }
  },
  methods: {
    toggleVisibility() {
      this.visibility = !this.visibility;
      return this.visibility
    }
  },
  computed: {
    imgPath() {
      return this.visibility? require(`../assets/images/${this.animationFilename}`) : null;
    }
  }
}

</script>