<template>
  <div class="error-label">
    <i18n
      :path="`${texts.path}`"
      tag="span"
    >
      <template v-slot:linkText>
        <a
          v-if="texts.link"
          :href="texts.link"
          target="_blank"
        >{{ $t(`${texts.linkText}`) }}</a>
      </template>
      <template v-slot:activationText>
        <a
          class="link"
          v-on:click="goToActivation()"
        >{{ $t(`${texts.activationText}`) }}</a>
      </template>
    </i18n>
  </div>
</template>

<style scoped>
  .error-label {
    left: 0;
    right: 0;
    margin: 0.5rem;
    padding: 1rem;
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 6px;
    text-align-last: left;
  }

  .link {
    cursor: pointer;
    color: blue;
  }
</style>

<script>
import Const from '@/services/Constants'

export default {
  props: {
    errorPath: {
      type: String,
      required: true
    }
  },
  computed: {
    texts () {
      let tempPath = this.errorPath
      let path
      let link
      let linkText
      let activationText
      if (!this.$te(tempPath)) {
        tempPath = 'common.errors.default'
      }
      path = tempPath

      if (this.$te(`${tempPath}.message`)) {
        path = `${tempPath}.message`
        linkText = `${tempPath}.linkText`
        if (this.$te(`${tempPath}.link`)) {
          link = process.env.VUE_APP_ERROR_URL_PREFIX + this.$t(`${tempPath}.link`)
        }
        activationText = `${tempPath}.activationText`
      }

      return {
        path: path,
        link: link,
        linkText: linkText,
        activationText: activationText
      }
    }
  },
  methods: {
    goToActivation () {
      this.$emit('errorPath', null)
      this.$router.push(Const.router.chooser)
    }
  }
}
</script>
