<template>
  <StyledLangSelect>
    <a id="current-locale-link" href="#" class="current-locale-link selected">{{ selected }}</a>
    <ul>
      <li v-for="(lang, key) in langs" :key="key"><a @click="changeLanguage(key)">{{ lang }}</a>
      </li>
    </ul>
  </StyledLangSelect>
</template>

<script>
import styled from 'vue-styled-components'
import themes from '@/assets/themes'

const StyledLangSelect = styled.div`
  font-family: Nunito;
  position: absolute;
  font-size: 0.875rem;
  top: 2rem;
  right: 1rem;
  text-align: right;
  z-index: 9999;

  @media only screen and (max-width: 1200px) {
    right: 0;
  }

& ul {
  background-color: #fff;
  display: none;
  padding: 0;
  margin-block-start: 6px;
  margin-right: 15px;

  border: 1px solid rgba(205, 206, 210, 0.6);
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(60, 52, 94, 0.1);
  border-radius: 4px;
}

& ul li {
  list-style: none;
  padding: 5px 20px;
}

& ul li:hover {
  background: rgba(229, 229, 229, 0.2);
}

&:hover ul {
  display: block;
}

& .current-locale-link {
  padding: 0 7px;
  margin-right: 30px;
  text-transform: uppercase;
}

& .current-locale-link:after {
  content: "";
  position: absolute;
  top: 3px;
  margin-left: 5px;
  border: solid ${themes.primaryColor};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

& a.selected {
  font-weight: 700;
}

& a {
  color: ${themes.textColor};
  text-align: right;
  font-size: 0.875rem;
  text-decoration: none;
  text-transform: uppercase;
}`

export default {
  components: {
    StyledLangSelect
  },
  data () {
    return {
      langs: {
        nl: 'NL',
        fr: 'FR',
        en: 'EN'
      },
      selected: null
    }
  },
  created () {
    if (localStorage.getItem('locale')) {
      this.selected = localStorage.getItem('locale')
      this.$i18n.locale = this.selected
    } else {
      this.selected = this.$i18n.locale
    }
    document.title = this.$t('common.title')
    document.documentElement.lang = this.$i18n.locale
  },
  methods: {
    changeLanguage (lang) {
      this.selected = lang;
      this.$i18n.locale = this.selected
      localStorage.setItem('locale', this.selected)
      document.title = this.$t('common.title')
      document.documentElement.lang = this.$i18n.locale
    }
  }
}
</script>
